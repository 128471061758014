<template>
  <div class="home">
    <header-search @search="handleSearch($event)"/>

    <div class="container--big container">
      <header-slider/>
    </div>

    <section-featured-courses
      :courses="featuredCourses"
      :title="$t('featuredCourses.title')"
      :subTitle="$t('featuredCourses.subTitle')"
      :bottomText="$t('featuredCourses.bottomText')"
    />

    <section-info
      purpose="user"
      v-bind="$t('sectionInfo.user')"
      id="for-users"
    />

    <section-benefits
      v-bind="$t('sectionBenefits.user')"
      :icons="['award', 'arrows', 'mobile']"
    />

    <section-info
      purpose="author"
      v-bind="$t('sectionInfo.author')"
      id="for-authors"
    />

    <section-benefits
      v-bind="$t('sectionBenefits.author')"
      :icons="['tachometer', 'price', 'smile']"
    />
    <!-- Commented for now -->
    <!-- <section-testimonials v-bind="$t('sectionTestimonials')" /> -->
    <section-ad v-bind="$t('sectionAd')"/>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  metaInfo: {
    title: 'Edunails International Training Platform for Nail Stylists. Innovative, smart, online learning & teaching marketplace.',
  },
  data: () => ({
    featuredCourses: null,
  }),
  computed: {
    slide() {
      return {
        ...this.$t('slides[0]'),
        banner: this.$t('slidesBanner[0]'),
        link: {
          href: '#',
          text: this.$t('learnMore'),
        },
        image: {
          src: '/assets/images/banner.png',
          alt: '',
        },
      };
    },
  },
  methods: {
    handleSearch(params) {
      this.$router.push({
        name: 'CoursesPage',
        params: {
          ...params,
        },
      });
    },
    scrollTo(hash) {
      const target = document.querySelector(hash);

      if (target) {
        target.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    },
  },
  watch: {
    $route() {
      this.$route.hash ? this.scrollTo(this.$route.hash) : false;
    },
  },
  mounted() {
    this.$route.hash ? this.scrollTo(this.$route.hash) : false;
  },
};
</script>

<style lang="scss" scoped src="./Home.scss"/>
